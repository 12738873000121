import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_HEAD_FUND_REBALANCE_EXISTING } from "../../../../../Methods";
import { dummyData } from "../../../../../dummyData";
import Approved from "./components/Approved";
import Adjusted from "./components/Adjusted";
import Rejected from "./components/Rejected";
import { useSelector } from "react-redux";

const FundRebalanceStep5 = ({ onNext, onPrevious }) => {
  const { approved_rejected } = useSelector((state) => state.todos);
  const [approved, setApproved] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [partial, setPartial] = useState([]);

  useEffect(() => {
    const approvedData = [];
    const rejectedData = [];
    const partialData = [];

    approved_rejected.forEach((item) => {
      if (item.available > 0 && item.available >= item.shareCount) {
        approvedData.push(item);
      } else if (item.available === 0) {
        rejectedData.push(item);
      } else if (item.available < item.shareCount && item.available > 0) {
        partialData.push(item);
      }
    });

    setApproved(approvedData);
    setRejected(rejectedData);
    setPartial(partialData);
  }, [approved_rejected]);

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="grey"
              startIcon={<ArrowBackIcon />}
              onClick={onPrevious}
              sx={{ width: "150px", height: "40px" }}
            >
              BACK
            </Button>

            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={onNext}
              sx={{ width: "150px", height: "40px" }}
            >
              Next
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "70%",
              justifyContent: "space-between",
            }}
          >
            <Approved
              approved={approved}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Rejected
              rejected={rejected}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />

            <Adjusted
              adjusted={partial}
              tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FundRebalanceStep5;
