import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Alert, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_HEAD_FUND_REBALANCE_BLACKLIST } from "../../../../../Methods";
import { locatesData as locates } from "../../../../../dummyData";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import Blacklisted from "./components/Blacklisted";
import { useDispatch, useSelector } from "react-redux";
import { updateLoading } from "../../../../../../Redux/Reducers/todoReducers";

const FundRebalanceStep4 = ({ setCurrentStep, onPrevious }) => {
  const dispatch = useDispatch();
  const { blacklist, identifier } = useSelector((state) => state.todos);
  const [popupOpen, setPopupOpen] = useState(false);
  const [blacklistData, setBlacklistData] = useState([]);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const onNext = () => {
    //  dispatch(updateLoading(true));
    const socketRequest = {
      action: "rebalancer_stage_two",
      data: {
        s3Key: identifier,
      },
    };

    window.clientSocks.send(JSON.stringify(socketRequest));
    setCurrentStep((prev) => prev + 1);
  };
  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                Blacklisted Securities
              </Typography>
            </div>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="grey"
                startIcon={<ArrowBackIcon />}
                onClick={onPrevious}
                sx={{ width: "150px", height: "40px" }}
              >
                BACK
              </Button>

              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "34.5%",
              justifyContent: "space-between",
            }}
          >
            <Alert severity="info">
              {" "}
              The securities that appear below, will not be counted in final
              rebalancer trades
            </Alert>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "35%",
              justifyContent: "space-between",
            }}
          >
            {locates && TABLE_HEAD_FUND_REBALANCE_BLACKLIST ? (
              <Blacklisted
                blacklist={blacklist}
                setBlacklistData={setBlacklistData}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_BLACKLIST}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep4;
