import {
  styled,
  TableCell,
  Box,
  Typography,
  Toolbar,
  OutlinedInput,
  Tab,
} from "@mui/material";
import moment from "moment/moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const getParentAccountName = (accounts, custodianId) => {
  for (const accountName in accounts) {
    if (accounts[accountName].custodian === custodianId) {
      return accountName;
    }
  }
  return null;
};
export const customizedFunds = (lots, funds, strategies) => {
  const findFundById = (fundId) => funds.find((fund) => fund.fundId === fundId);
  const findStrategyById = (strategyId) =>
    strategies.find((strategy) => strategy.strategyId === strategyId);

  const result = [];

  lots.forEach((lot) => {
    const fund = findFundById(lot.fund);
    const strategy = lot.strategy ? findStrategyById(lot.strategy) : null;

    let fundInResult = result.find((item) => item.fundId === fund.fundId);

    if (!fundInResult) {
      fundInResult = {
        fundId: fund?.fundId,
        abbreviation: fund?.abbreviation,
        name: fund?.name,
        strategies: [],
      };
      result.push(fundInResult);
    }

    if (strategy) {
      const strategyInResult = fundInResult.strategies.find(
        (item) => item.id === strategy.strategyId
      );
      if (!strategyInResult) {
        fundInResult.strategies.push({
          id: strategy.strategyId,
          name: strategy.name,
        });
      }
    }
  });

  return result;
};
export const findBrokerName = (id, brokers) => {
  const broker = brokers?.find((i) => {
    return i?.brokerId == id;
  });
  return broker?.name;
};
export const findBrokerAbb = (id, brokers) => {
  const broker = brokers?.find((i) => {
    return i?.brokerId == id;
  });
  return broker?.abbreviation;
};

export const findStrategy = (id, strategies) => {
  const strategy = strategies?.find((i) => {
    return i?.strategyId == id;
  });
  return strategy?.name;
};
export const findStrategyById = (name, strategies) => {
  const strategy = strategies?.find((i) => {
    return i?.name == name;
  });
  return strategy?.strategyId;
};
export const findFundById = (abbreviation, funds) => {
  const fund = funds?.find((i) => {
    return i?.abbreviation == abbreviation;
  });
  return fund?.fundId;
};
export const findFundByName = (name, funds) => {
  const fund = funds?.find((i) => {
    return i?.name == name;
  });
  return fund?.fundId;
};
export const findFund = (id, funds) => {
  const fund = funds?.find((i) => {
    return i?.fundId == id;
  });
  return fund?.name;
};
export const findCust = (id, custodians) => {
  const custodian = custodians?.find((i) => {
    return i?.custodianId == id;
  });
  return custodian?.name;
};
export const findCustId = (name, custodians) => {
  const custodian = custodians?.find((i) => {
    return i?.abbreviation == name;
  });
  return custodian?.custodianId;
};

//format number
export const calculateAndFormat = (value) => {
  if (value && value != "") {
    value = parseFloat(value);
    const roundedValue = Math.round(value);
    return roundedValue.toLocaleString();
  } else return value;
};
export const calculateAndFormatNoAbsInt = (value) => {
  if (value !== "" && !isNaN(value)) {
    const integerValue = parseInt(value);
    return integerValue.toLocaleString(undefined, { minimumFractionDigits: 0 });
  } else {
    return value;
  }
};

export const calculateAndFormatNoAbs = (value) => {
  if (value && value !== "") {
    const numericValue = Math.floor(parseFloat(value));
    return numericValue.toLocaleString();
  } else {
    return value;
  }
};

export const formatValue = (value, nodecimal = false) => {
  if (value && value != "") {
    const parsedValue = Math.abs(parseFloat(value));

    if (value === 0) {
      return "0.00";
    }
    if (parsedValue >= 1000) {
      if (nodecimal) {
        return parsedValue.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else {
        return parsedValue.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    if (nodecimal) {
      return parsedValue.toFixed(0);
    } else {
      return parsedValue.toFixed(2);
    }
  } else return value;
};

export const twoDecimal = (value) => {
  if (value && value != "") {
    const parsedValue = parseFloat(value);
    return parsedValue.toFixed(2);
  } else return value;
};

export const formatValueDecimal = (value) => {
  if (value && value != "") {
    const parsedValue = parseFloat(value);

    if (value == 0) {
      return "0.00";
    }
    if (parsedValue >= 1000) {
      return parsedValue.toLocaleString("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return parsedValue.toFixed(2);
  } else return value;
};

//find strategy
export const findName = (key, strategies) => {
  if (key && strategies) {
    const findName = strategies?.find((i) => {
      return i.strategyId == key;
    });
    return findName?.name;
  } else {
    return key;
  }
};
export const findAum = (key, funds) => {
  if (key && funds) {
    const findName = funds?.find((i) => {
      return i.fund == key;
    });
    return findName?.aum;
  } else {
    return key;
  }
};
//function to return trades
export const historicalData = (
  historical,
  funds,
  brokers,
  strategies,
  custodians
) => {
  const SimpleArray = [];
  historical?.forEach((tradeObj, i) => {
    const simplerObject = {};
    const noAction = true;
    const tradeObject = tradeObj;
    const index = 1;
    const fundStatus = tradeObj?.fund ? true : false;
    const tradeDate = tradeObj?.date;
    const tradeType = hashAction(tradeObj?.action);
    const tradeAmount = tradeObj?.units?.toFixed(1);
    const completed = tradeObj?.units?.toFixed(1);
    const router = "emsx";
    const sec = "";
    const occ = "";
    const orf = "";
    let working = "";
    let tradeFills = "";
    let price = " ";
    let PriceManual = " ";
    const commission = "";
    const instructions = tradeObj?.instructions;
    const orderType = hashType(tradeObj?.type);
    const routing = tradeObj.router != undefined ? "Yes" : "No";
    const currency = hashCurrencyEnum(tradeObj?.ticker?.currency);
    const investment = hashInvestment(tradeObj?.ticker?.investment);
    const position = hashPosition(tradeObj?.position);

    const strikePrice = tradeObj?.ticker?.strikePrice
      ? Number(tradeObj?.ticker?.strikePrice)
      : " ";
    const option = hashOption(tradeObj?.ticker?.option);

    const expiration = tradeObj?.ticker?.expirationDate
      ? moment(tradeObj?.ticker?.expirationDate)?.format("MMM/DD/YY")
      : " ";
    const formattedExpiration = tradeObj?.ticker?.expirationDate
      ? moment(tradeObj?.ticker?.expirationDate)?.format("MMM/DD")
      : " ";
    const ticker =
      tradeObj?.ticker?.investment == TradeInvestment.Options
        ? `${tradeObj?.ticker?.symbol} ${formattedExpiration} ${strikePrice} ${option}`
        : tradeObj?.ticker?.symbol;
    //broker
    if (brokers) {
      let name = brokers?.find((a) => a.brokerId === tradeObj?.broker);
      let broker;
      if (name) {
        broker = name.abbreviation;
      } else {
        broker = "";
      }
      simplerObject["brokerId"] = broker;
    }

    //fund
    if (funds) {
      let fund = "";
      let name = funds?.find((a) => a.fundId == tradeObj?.fund);
      if (name) {
        fund = name?.abbreviation;
      } else {
        fund = "";
      }

      simplerObject["client"] = fund;
    }
    //strategy
    if (strategies) {
      let strategy = "";
      let name = strategies?.find((a) => a.strategyId === tradeObj?.strategy);
      if (name) {
        strategy = name?.name;
      } else {
        strategy = "";
      }

      simplerObject["strategy"] = strategy;
    }

    if (custodians) {
      let custodian = "";
      let name = custodians?.find((a) => a.strategyId === tradeObj?.custodian);
      if (name) {
        custodian = name?.name;
      } else {
        custodian = "";
      }

      simplerObject["prime"] = custodian;
    }

    simplerObject["index"] = index;
    simplerObject["noAction"] = noAction;
    simplerObject["tradeObject"] = tradeObject;
    simplerObject["fundStatus"] = fundStatus;
    simplerObject["TradeTime"] = tradeDate;
    simplerObject["TradeType"] = tradeType;
    simplerObject["Pcm"] = ticker;
    simplerObject["ordered"] = Number(tradeAmount);
    simplerObject["working"] = Number(working);
    simplerObject["tradefills"] = tradeFills;
    simplerObject["completed"] = Number(completed);
    simplerObject["price"] = Number(price);
    simplerObject["PriceManual"] = PriceManual;
    simplerObject["comm"] = commission;
    simplerObject["oms"] = router;
    simplerObject["sec"] = sec;
    simplerObject["occ"] = occ;
    simplerObject["orf"] = orf;
    simplerObject["Ins"] = instructions;
    simplerObject["ordertype"] = orderType;
    simplerObject["routing"] = routing;
    simplerObject["currency"] = currency;
    simplerObject["InvestmentType"] = investment;
    simplerObject["PositionType"] = position;
    simplerObject["expiration"] = expiration;
    simplerObject["strikeprice"] = strikePrice;
    simplerObject["putsorcall"] = option;
    SimpleArray.push(simplerObject);
  });
  return SimpleArray;
};
export const tradesData = (
  trades,
  funds,
  brokers,
  routeList,
  strategies,
  custodians,
  prioritySwitch
) => {
  const SimpleArray = [];
  trades?.forEach((tradeObj, i) => {
    const simplerObject = {};
    const tradeId = tradeObj.tradeId;
    const lastOrder = tradeObj.orders.slice(-1)[0];
    const tradeObject = tradeObj;
    const index = i;

    if (lastOrder) {
      const distribute = Object.entries(lastOrder?.distribution || {}).reduce(
        (acc, [key, value]) => {
          const [account, fund, _] = key.split("#");
          const strategySplit = key.split("#0#");
          const strategy = strategySplit.length > 1 ? strategySplit[1] : "";

          if (!acc[fund]) {
            acc[fund] = [];
          }

          acc[fund].push({
            shareCount: Number(value),
            account: account,
            strategy: findStrategy(strategy, strategies),
            child: "C",
            fund: findFund(fund, funds),
          });

          return acc;
        },
        {}
      );

      const fundStatus = Object.keys(distribute)?.length > 0 ? true : false;
      const tradeDate = lastOrder?.date;
      const tradeType =
        hashAction(lastOrder?.action) + hashPosition(lastOrder?.position);

      const distribution = distribute;
      let totalShareCount = 0;
      if (distribution) {
        Object.keys(distribution).forEach((key) => {
          const entries = distribution[key];
          totalShareCount += entries.reduce(
            (sum, entry) => sum + entry.shareCount,
            0
          );
        });
        const firstDistributionKey = Object.keys(distribution)?.[0];
        const firstDistribution = distribution[firstDistributionKey];
        if (firstDistribution) {
          const distributionsWithUniqueId =
            firstDistribution?.map((distributionObj, i) => ({
              ...distributionObj,
              id: i + 1,
            })) || [];

          simplerObject["distributions"] = distributionsWithUniqueId;
          simplerObject["parent"] = "P";
        } else {
          simplerObject["distributions"] = [];
          simplerObject["parent"] = "S";
        }
      }
      const tradeAmount = totalShareCount.toFixed(1);

      const sec = "";
      const occ = "";
      const orf = "";
      let working = "";
      let tradeFills = "";
      let completed = "";
      let price = " ";
      let PriceManual = " ";
      const instructions = lastOrder?.instructions;
      const orderType = hashType(lastOrder?.type);
      const routing = lastOrder.router != undefined ? "Yes" : "No";
      const currency = hashCurrencyEnum(lastOrder?.ticker?.currency);
      const investment = hashInvestment(lastOrder?.ticker?.investment);
      const position = hashPosition(lastOrder?.position);

      const strikePrice = lastOrder?.ticker?.strikePrice
        ? Number(lastOrder?.ticker?.strikePrice)
        : " ";
      const option = hashOption(lastOrder?.ticker?.option);
      const expiration = lastOrder?.ticker?.expirationDate
        ? moment(lastOrder?.ticker?.expirationDate)?.format("MMM/DD/YY")
        : " ";
      const formattedExpiration = lastOrder?.ticker?.expirationDate
        ? moment(lastOrder?.ticker?.expirationDate)?.format("MMM/DD")
        : " ";

      const ticker =
        lastOrder?.ticker?.investment == TradeInvestment.Options
          ? `${lastOrder?.ticker?.symbol} ${formattedExpiration} ${strikePrice} ${option}`
          : lastOrder?.ticker?.symbol;

      //workingShares tradefills price completed
      if (
        prioritySwitch === EnumpriorityTrades.Manual ||
        prioritySwitch == EnumpriorityTrades.All
      ) {
        if (tradeObj.manuals && tradeObj.manuals.length > 0) {
          working = ManualWorkingShares(totalShareCount, tradeObj.manuals);
          tradeFills = "M";
          completed = ManualShareCount(tradeObj.manuals);
          price = ManualSharePrice(tradeObj.manuals);
          PriceManual = true;
        } else if (tradeObj.fill && Object.keys(tradeObj.fill).length > 0) {
          working = FillWorkingShares(totalShareCount, tradeObj.fill);
          tradeFills = "A";
          completed = FillShareCount(tradeObj.fill);
          price = FillSharePrice(tradeObj.fill);
          PriceManual = false;
        } else {
          working = totalShareCount?.toFixed(4);
          tradeFills = "";
          completed = 0;
          price = 0;
          PriceManual = false;
        }
      } else if (prioritySwitch === EnumpriorityTrades.Auto) {
        if (tradeObj.fill && Object.keys(tradeObj.fill).length > 0) {
          working = FillWorkingShares(totalShareCount, tradeObj.fill);
          tradeFills = "A";
          completed = FillShareCount(tradeObj.fill);
          price = FillSharePrice(tradeObj.fill);
          PriceManual = false;
        } else if (tradeObj.manuals && tradeObj.manuals.length > 0) {
          working = ManualWorkingShares(totalShareCount, tradeObj.manuals);
          tradeFills = "M";
          completed = ManualShareCount(tradeObj.manuals);
          price = ManualSharePrice(tradeObj.manuals);
          PriceManual = true;
        } else {
          working = totalShareCount?.toFixed(4);
          tradeFills = "";
          completed = 0;
          price = 0;
          PriceManual = false;
        }
      }

      //broker
      if (brokers) {
        let name = brokers?.find((a) => a.brokerId === lastOrder?.broker);
        let broker;
        if (name) {
          broker = name.abbreviation;
        } else {
          broker = "";
        }
        simplerObject["brokerId"] = broker;
      }
      //commission
      if (tradeObj?.commission) {
        const commission = tradeObj?.commission?.commission;
        simplerObject["comm"] = commission;
      }
      //router
      if (tradeObj?.router && routeList && routeList.length > 0) {
        let router = "";
        let name = routeList?.find(
          (a) => a.routerId == tradeObj?.router?.router
        );
        if (name) {
          router = name.abbreviation;
        } else {
          router = "";
        }
        simplerObject["oms"] = router;
      }

      //strategy
      if (strategies) {
        for (const fund in distribute) {
          if (distribute[fund].length === 1) {
            simplerObject["strategy"] = distribute[fund][0]?.strategy;
            break;
          }
        }
      }

      if (funds && funds.length > 0 && lastOrder.distribution) {
        let custodian = "";

        const distribution = distribute;

        if (distribution && Object.keys(distribution).length > 0) {
          const firstDistributionKey = Object.keys(distribution)[0];
          const distributionArray = distribution[firstDistributionKey];

          if (distributionArray) {
            if (distributionArray.length > 1) {
              custodian = "multiple";
            } else if (distributionArray.length === 1) {
              const fundAcc = distributionArray[0].account;
              const fundObj = funds.find(
                (i) => i.fundId === firstDistributionKey
              );

              if (
                fundObj &&
                fundObj.accounts &&
                Object.keys(fundObj.accounts).length > 0 &&
                fundAcc
              ) {
                const key = fundObj.accounts[fundAcc];

                if (key && key.custodian && custodians) {
                  const custodianName = custodians.find(
                    (a) => a.custodianId === key.custodian
                  );
                  custodian = custodianName?.abbreviation || "";
                }
              }
            }
          }
        }

        simplerObject["prime"] = custodian;
      }
      ///fund
      if (funds && funds.length > 0 && distribute) {
        let fund = "";

        const distribution = distribute;

        if (distribution && Object.keys(distribution).length > 0) {
          const firstDistributionKey = Object.keys(distribution)[0];
          const distributionArray = distribution[firstDistributionKey];

          if (distributionArray) {
            const firstFund = distributionArray[0].fund;
            const allSameFund = distributionArray.every(
              (item) => item.fund === firstFund
            );

            if (allSameFund) {
              fund = firstFund;
            } else {
              fund = "multiple";
            }
          }
        }

        simplerObject["client"] = fund;
      }
      ///strategy
      if (strategies && strategies.length > 0 && distribute) {
        let strategy = "";

        const distribution = distribute;

        if (distribution && Object.keys(distribution).length > 0) {
          const firstDistributionKey = Object.keys(distribution)[0];
          const distributionArray = distribution[firstDistributionKey];

          if (distributionArray) {
            const firstStrategy = distributionArray[0].strategy;
            const allSameStrategy = distributionArray.every(
              (item) => item.strategy === firstStrategy
            );

            if (allSameStrategy) {
              strategy = firstStrategy;
            } else {
              strategy = "multiple";
            }
          }
        }

        simplerObject["strategy"] = strategy;
      }

      simplerObject["index"] = index;
      simplerObject["tradeObject"] = tradeObject;
      simplerObject["fundStatus"] = fundStatus;
      simplerObject["TradeTime"] = tradeDate;
      simplerObject["TradeType"] = tradeType;
      simplerObject["Pcm"] = ticker;
      simplerObject["ordered"] = Number(tradeAmount);
      simplerObject["working"] = Number(working);
      simplerObject["tradefills"] = tradeFills;
      simplerObject["completed"] = Number(completed);
      simplerObject["price"] = Number(price);
      simplerObject["PriceManual"] = PriceManual;
      simplerObject["sec"] = sec;
      simplerObject["occ"] = occ;
      simplerObject["orf"] = orf;
      simplerObject["Ins"] = instructions;
      simplerObject["ordertype"] = orderType;
      simplerObject["routing"] = routing;
      simplerObject["currency"] = currency;
      simplerObject["InvestmentType"] = investment;
      simplerObject["PositionType"] = position;
      simplerObject["expiration"] = expiration;
      simplerObject["strikeprice"] = strikePrice;
      simplerObject["putsorcall"] = option;
      simplerObject["tradeId"] = tradeId;
      SimpleArray.push(simplerObject);
    }
  });
  return SimpleArray;
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export const handleDateBackward = (st, en) => {
  let start = new Date(st - 86400000).getTime();
  let end = new Date(en - 86400000).getTime();
  return { start, end };
};

export const handleDateForward = (st, en) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  if (new Date().getTime() - 86400000 > st) {
    let start = new Date(st + 86400000).getTime();
    let end = new Date(en + 86400000).getTime();

    return { start, end };
  } else if (today.getTime() > st) {
    const start = new Date(
      currentYear,
      currentMonth,
      currentDay,
      0,
      0,
      0
    ).getTime();
    const end = new Date(
      currentYear,
      currentMonth,
      currentDay,
      23,
      59,
      59
    ).getTime();
    return { start, end };
  } else {
    return { start: st, end: en };
  }
};

export const dataFormat = (symbol, exp, strike, opt) => {
  const formatData = [
    symbol,
    moment.unix(exp).format("MMMDD"),
    Number(strike),
    hashOption(Number(opt)),
  ].join(" ");
  return formatData;
};
export function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
export function convertToDateString(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function applySortFilter(array, comparator, query) {
  if (array && array.length > 0) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return array.filter(
        (_) =>
          _?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          _?.abbreviation?.toLowerCase()?.includes(query?.toLowerCase())
      );
    }
    return stabilizedThis?.map((el) => el[0]);
  } else {
    return [];
  }
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const checkStringType = (inputString) => {
  if (/^[a-zA-Z0-9]+$/.test(inputString)) {
    return 1;
  }
  if (/^[a-zA-Z ]+[.\/ ][a-zA-Z0-9 ]+$/.test(inputString)) {
    return 2;
  }

  return 3;
};

export const extractTickerAndWeight = (entry) => {
  const match = entry.match(/(.+?)\s*([+-]?\d+(\.\d+)?)\s*%?\s*$/);
  if (!match) {
    return null;
  }

  let value = parseFloat(match[2]);
  if (!entry.includes("%")) {
    value *= 100;
  }
  let name = match[1].trim();
  const { ticker, isValid } = inputChecker(name);
  const { symbol, country, currency, investment, stock } = ticker;
  const stockProps = stock ? { stock } : {};

  return {
    symbol: symbol,
    value: parseFloat(value)?.toFixed(2),
    name: name,
    country: country,
    investment: investment,
    currency: currency,
    invalid: !isValid,
    ...stockProps,
  };
};

export const nameVer = (name) => {
  const lastChar = name.slice(-1);
  if (!/[a-zA-Z]/.test(lastChar)) {
    name = name.slice(0, -1);
  }
  name = name.split(/\s+/)[0];

  name = name.replace(/us equity/i, "");

  name = name.trim();

  if (checkStringType(name) === 1) {
    return name;
  }

  if (checkStringType(name) === 2) {
    let stock = name.charAt(name.length - 1);
    return { name, stock };
  }
};
export const ManualWorkingShares = (totalShareCount, manuals) => {
  return (totalShareCount - manuals?.slice(-1)[0]?.shareCount)?.toFixed(4);
};
export const FillWorkingShares = (totalShareCount, fill) => {
  return (totalShareCount - fill?.shareCount)?.toFixed(4);
};

export const ManualShareCount = (manuals) => {
  return manuals?.slice(-1)[0]?.shareCount?.toFixed(4);
};
export const FillShareCount = (fill) => {
  return fill?.shareCount?.toFixed(4);
};
export const ManualSharePrice = (manuals) => {
  return manuals?.slice(-1)[0]?.sharePrice?.toFixed(4);
};
export const FillSharePrice = (fill) => {
  return fill?.sharePrice?.toFixed(4);
};
// export const ManualCommission = (manuals) => {
//   return (
//     manuals?.slice(-1)[0]?.commission / manuals?.slice(-1)[0]?.shareCount
//   )?.toFixed(4);
// };
// export const FillCommission = (fill) => {
//   if (fill.commission && fill.commission != undefined)
//     return (fill?.commission / fill?.shareCount)?.toFixed(4);
//   else return 0;
// };
export const priorityTrades = ["Auto", "Manual", "All"];
export const EnumpriorityTrades = { Auto: 0, Manual: 1, All: 2 };
export const Positions = ["Long", "Short"];
export const EnumPosition = { Long: 0, Short: 1 };
export const hashAction = (val) => {
  switch (val) {
    case 0:
      return "Buy";
    case 1:
      return "Exercised";
    case 2:
      return "Expired";
    case 3:
      return "Sell";
    default:
      return null;
  }
};

export const hashPosition = (val) => {
  switch (val) {
    case 0:
      return "Long";
    case 1:
      return "Short";
    default:
      return null;
  }
};
export const hashRouting = (val) => {
  switch (val) {
    case 0:
      return "Auto";
    case 1:
      return "Manual";
    default:
      return null;
  }
};

export const hashInvestment = (val) => {
  switch (val) {
    case 0:
      return "Bond";
    case 1:
      return "Commodity";
    case 2:
      return "Crypto";
    case 3:
      return "Currency";
    case 4:
      return "Equity";
    case 5:
      return "Future";
    case 6:
      return "Government";
    case 7:
      return "Index";
    case 8:
      return "MoneyMarket";
    case 9:
      return "Option";
    case 10:
      return "Partnership";
    case 11:
      return "Preferred";
    case 12:
      return "Private";
    case 13:
      return "Right";
    case 14:
      return "Swap";
    case 15:
      return "Unknown";
    case 16:
      return "Warrant";
    default:
      return null;
  }
};
export const hashInvestmentReverse = (str) => {
  switch (str.toLowerCase()) {
    case "bond":
      return 0;
    case "commodity":
      return 1;
    case "crypto":
      return 2;
    case "currency":
      return 3;
    case "equity":
      return 4;
    case "future":
      return 5;
    case "government":
      return 6;
    case "index":
      return 7;
    case "moneymarket":
      return 8;
    case "option":
      return 9;
    case "partnership":
      return 10;
    case "preferred":
      return 11;
    case "private":
      return 12;
    case "right":
      return 13;
    case "swap":
      return 14;
    case "unknown":
      return 15;
    case "warrant":
      return 16;
    default:
      return null;
  }
};

export const hashTradeAction = (val) => {
  switch (val) {
    case 0:
      return 0;
    case 1:
      return 3;
    case 2:
      return 3;
    case 3:
      return 0;
    default:
      return null;
  }
};
export const hashTradePosition = (val) => {
  switch (val) {
    case 0:
      return 0;
    case 1:
      return 0;
    case 2:
      return 1;
    case 3:
      return 1;
    default:
      return null;
  }
};
export const hashTradeType = (action, position) => {
  if (action == 0 && position == 0) {
    return 0;
  } else if (action == 3 && position == 0) {
    return 1;
  } else if (action == 3 && position == 1) {
    return 2;
  } else if (action == 0 && position == 1) {
    return 3;
  }
};
export const hashType = (val) => {
  switch (val) {
    case 0:
      return "Limit";
    case 1:
      return "Market";
    case 2:
      return "MarketOnClose";
    case 3:
      return "Stop";
    case 4:
      return "VWAP";
    default:
      return null;
  }
};
export const hashOption = (val) => {
  switch (val) {
    case 0:
      return "calls";
    case 1:
      return "puts";
    default:
      return null;
  }
};
export const hashOptionEnum = (val) => {
  switch (val) {
    case "Calls":
      return 0;
    case "Puts":
      return 1;
    default:
      return null;
  }
};
export const hashCurrencyEnumString = (str) => {
  switch (str.toLowerCase()) {
    case "aud":
      return 0;
    case "cad":
      return 1;
    case "eur":
      return 2;
    case "gbp":
      return 3;
    case "hkd":
      return 4;
    case "jpy":
      return 5;
    case "nok":
      return 6;
    case "usd":
      return 7;
    case "zar":
      return 8;
    default:
      return null;
  }
};
export const hashCurrencyEnum = (val) => {
  switch (val) {
    case 0:
      return "AUD";
    case 1:
      return "CAD";
    case 2:
      return "EUR";
    case 3:
      return "GBP";
    case 4:
      return "HKD";
    case 5:
      return "JPY";
    case 6:
      return "NOK";
    case 7:
      return "USD";
    case 8:
      return "ZAR";
    default:
      return null;
  }
};
export const hashExchangeEnumReverse = (str) => {
  switch (str.toUpperCase()) {
    case "CN":
      return 0;
    case "FP":
      return 1;
    case "LN":
      return 2;
    case "SJ":
      return 3;
    case "US":
      return 4;
    default:
      return null;
  }
};
export const hashExchangeEnum = (val) => {
  switch (val) {
    case 0:
      return "CN";
    case 1:
      return "FP";
    case 2:
      return "LN";
    case 3:
      return "SJ";
    case 4:
      return "US";
    default:
      return null;
  }
};
export const performance_list = [
  { id: 1, name: "Daily" },
  { id: 2, name: "Weekly" },
  { id: 3, name: "Monthly" },
  { id: 4, name: "Quarterly" },
  { id: 5, name: "Yearly" },
];
export const durationEOD = ["EOD", "GTC", "CUSTOM"];
export const EnumDurationTrades = { EOD: 0, GTC: 1, CUSTOM: 2 };
export const underlyingTickers = ["value a", "value b", "value c"];
export const order_Type = ["Limit", "Market", "MarketOnClose", "Stop", "VWAP"];
export const putsCalls = ["Calls", "Puts"];
export const investArr = [
  "Bond",
  "Commodity",
  "Crypto",
  "Currency",
  "Equity",
  "Future",
  "Government",
  "Index",
  "MoneyMarket",
  "Option",
  "Partnership",
  "Preferred",
  "Private",
  "Right",
  "Swap",
  "Unknown",
  "Wrrant",
];
export const tradeArr = [
  { key: "Buy", value: 0 },
  { key: "Sell", value: 1 },
  { key: "Short", value: 2 },
  { key: "Cover", value: 3 },
];
export const tradeArr2 = [
  { key: "Buy to open", value: 0 },
  { key: "Sell to close", value: 1 },
  { key: "Sell to open", value: 2 },
  { key: "Buy to close", value: 3 },
];
export const TradeInvestment = {
  Equity: 4,
  Options: 9,
};

export const columns = [
  {
    field: "0",
    headerName: "Prime Broker Account No",
    editable: true,
  },
  {
    field: "1",
    headerName: "Unused Field",
    editable: true,
  },
  {
    field: "2",
    headerName: "Trading Unit",
    editable: true,
  },
  {
    field: "3",
    headerName: "Trading Sub Unit",
    editable: true,
  },
  {
    field: "4",
    headerName: "Record Type",
    editable: true,
  },
  {
    field: "5",
    headerName: "Transaction Type",
    editable: true,
  },
  {
    field: "6",
    headerName: "Client Transaction ID",
    editable: true,
  },
  {
    field: "7",
    headerName: "Client Block ID",
    editable: true,
  },
  {
    field: "8",
    headerName: "Client Original Transaction ID",
    editable: true,
  },
  {
    field: "9",
    headerName: "Client Asset Type",
    editable: true,
  },
  {
    field: "10",
    headerName: "Client Product ID Type",
    editable: true,
  },
  {
    field: "11",
    headerName: "Client Product ID",
    editable: true,
  },
  {
    field: "12",
    headerName: "Country Of Trading ",
    editable: true,
  },
  {
    field: "13",
    headerName: "Client Product Description",
    editable: true,
  },
  {
    field: "14",
    headerName: "Client Executing Broker",
    editable: true,
  },

  {
    field: "15",
    headerName: "Trade Date",
    editable: true,
  },
  {
    field: "16",
    headerName: "Contractual Settlement Date",
    editable: true,
  },
  {
    field: "17",
    headerName: "Spot Date",
    editable: true,
  },
  {
    field: "18",
    headerName: "Price",
    editable: true,
  },
  {
    field: "19",
    headerName: "Issue Currency",
    editable: true,
  },
  {
    field: "20",
    headerName: "Settlement Currency",
    editable: true,
  },
  {
    field: "21",
    headerName: "Cost Basis FX Rate",
    editable: true,
  },
  {
    field: "22",
    headerName: "Quantity",
    editable: true,
  },
  {
    field: "23",
    headerName: "Commission Amount",
    editable: true,
  },
  {
    field: "24",
    headerName: "Commission Rate",
    editable: true,
  },
  {
    field: "25",
    headerName: "Commission Type",
    editable: true,
  },
  {
    field: "26",
    headerName: "SEC FEE",
    editable: true,
  },
  {
    field: "27",
    headerName: "Fee Type 1",
    editable: true,
  },
  {
    field: "28",
    headerName: "Fee Amount 1",
    editable: true,
  },
  {
    field: "29",
    headerName: "Fee Type 2",
    editable: true,
  },
  {
    field: "30",
    headerName: "Fee Amount 2",
    editable: true,
  },
  {
    field: "31",
    headerName: "Fee Type 3",
    editable: true,
  },
  {
    field: "32",
    headerName: "Fee Amount 3",
    editable: true,
  },
  {
    field: "33",
    headerName: "Fee Type 4",
    editable: true,
  },
  {
    field: "34",
    headerName: "Fee Amount 4",
    editable: true,
  },
  {
    field: "35",
    headerName: "Fee Type 5",
    editable: true,
  },
  {
    field: "36",
    headerName: "Fee Amount 5",
    editable: true,
  },
  {
    field: "37",
    headerName: "Accrued Interest",
    editable: true,
  },
  {
    field: "38",
    headerName: "Net Amount",

    editable: true,
  },
  {
    field: "39",
    headerName: "Option Type",
    editable: true,
  },
  {
    field: "40",
    headerName: "Strike Price",
    editable: true,
  },
  {
    field: "41",
    headerName: "Expiration Date",
    editable: true,
  },
  {
    field: "42",
    headerName: "Client Underlying Product ID Type",
    editable: true,
  },
  {
    field: "43",
    headerName: "Client Underlying Product ID",
    editable: true,
  },
  {
    field: "44",
    headerName: "Client Comments",
    editable: true,
  },
];

export const percolumns = [
  {
    field: "0",
    headerName: "LOCALREF",
    editable: true,
  },
  {
    field: "1",
    headerName: "CFID",
    editable: true,
  },
  {
    field: "2",
    headerName: "ROUTECD",
    editable: true,
  },
  {
    field: "3",
    headerName: "TIRORDERID",
    editable: true,
  },
  {
    field: "4",
    headerName: "TIRPIECE",
    editable: true,
  },
  {
    field: "5",
    headerName: "TIRSEQ",
    editable: true,
  },
  {
    field: "6",
    headerName: "SECIDTYPE",
    editable: true,
  },
  {
    field: "7",
    headerName: "SECURITYID",
    editable: true,
  },
  {
    field: "8",
    headerName: "DESCRIPTION1",
    editable: true,
  },
  {
    field: "9",
    headerName: "DESCRIPTION2",
    editable: true,
  },
  {
    field: "10",
    headerName: "DESCRIPTION3",
    editable: true,
  },
  {
    field: "11",
    headerName: "DESCRIPTION4",
    editable: true,
  },
  {
    field: "12",
    headerName: "TRADEDATE",
    editable: true,
  },
  {
    field: "13",
    headerName: "SETLDATE",
    editable: true,
  },
  {
    field: "14",
    headerName: "QUANTITY",
    editable: true,
  },

  {
    field: "15",
    headerName: "QUANTITYDESC",
    editable: true,
  },
  {
    field: "16",
    headerName: "NETMONEY",
    editable: true,
  },
  {
    field: "17",
    headerName: "CASHACCOUNT",
    editable: true,
  },
  {
    field: "18",
    headerName: "SECACCOUNT",
    editable: true,
  },
  {
    field: "19",
    headerName: "TRADECURRID",
    editable: true,
  },
  {
    field: "20",
    headerName: "SETLCURRID",
    editable: true,
  },
  {
    field: "21",
    headerName: "BSIND",
    editable: true,
  },
  {
    field: "22",
    headerName: "INSTTYP",
    editable: true,
  },
  {
    field: "23",
    headerName: "PRICE",
    editable: true,
  },
  {
    field: "24",
    headerName: "COMMISSION",
    editable: true,
  },
  {
    field: "25",
    headerName: "STAMPTAX",
    editable: true,
  },
  {
    field: "26",
    headerName: "LOCALCHGS",
    editable: true,
  },
  {
    field: "27",
    headerName: "INTEREST",
    editable: true,
  },
  {
    field: "28",
    headerName: "PRINCIPAL",
    editable: true,
  },
  {
    field: "29",
    headerName: "SECFEE",
    editable: true,
  },
  {
    field: "30",
    headerName: "EXECBROKER",
    editable: true,
  },
  {
    field: "31",
    headerName: "BROKEROS",
    editable: true,
  },
  {
    field: "32",
    headerName: "TRAILERCD1",
    editable: true,
  },
  {
    field: "33",
    headerName: "TRAILERCD2",
    editable: true,
  },
  {
    field: "34",
    headerName: "TRAILERCD3",
    editable: true,
  },
  {
    field: "35",
    headerName: "BLOTTERCD",
    editable: true,
  },
  {
    field: "36",
    headerName: "CLRNGHSE",
    editable: true,
  },
  {
    field: "37",
    headerName: "CLRAGNTCD",
    editable: true,
  },
  {
    field: "38",
    headerName: "CLRAGNT1",

    editable: true,
  },
  {
    field: "39",
    headerName: "CLRAGNT2",
    editable: true,
  },
  {
    field: "40",
    headerName: "CLRAGNT3",
    editable: true,
  },
  {
    field: "41",
    headerName: "CLRAGNT4",
    editable: true,
  },
  {
    field: "42",
    headerName: "CNTRPRTYCD",
    editable: true,
  },
  {
    field: "43",
    headerName: "CNTRPTY1",
    editable: true,
  },
  {
    field: "44",
    headerName: "CNTRPTY2",
    editable: true,
  },
  {
    field: "45",
    headerName: "CNTRPTY3",
    editable: true,
  },
  {
    field: "46",
    headerName: "CNTRPTY4",
    editable: true,
  },
  {
    field: "47",
    headerName: "INSTRUCT",
    editable: true,
  },
  {
    field: "48",
    headerName: "CEDELAKV",
    editable: true,
  },
  {
    field: "49",
    headerName: "ORIGLOCALREF",
    editable: true,
  },
  {
    field: "50",
    headerName: "NOTES",
    editable: true,
  },
  {
    field: "51",
    headerName: "FILLER",
    editable: true,
  },
  {
    field: "52",
    headerName: "FILLER",
    editable: true,
  },
  {
    field: "53",
    headerName: "RR",
    editable: true,
  },
  {
    field: "54",
    headerName: "SETLLOCATIONCD",
    editable: true,
  },
  {
    field: "55",
    headerName: "INSTRUMENTTYPE",
    editable: true,
  },
];
export const accountingCols = [
  {
    field: "0",
    headerName: "Fund",
    editable: true,
  },
  {
    field: "1",
    headerName: "Account#",
    editable: true,
  },
  {
    field: "2",
    headerName: "Ticker",
    editable: true,
  },
  {
    field: "3",
    headerName: "Description",
    editable: true,
  },
  {
    field: "4",
    headerName: "Broker",
    editable: true,
  },
  {
    field: "5",
    headerName: "Transaction Type",
    editable: true,
  },
  {
    field: "6",
    headerName: "Trade Date",
    editable: true,
  },
  {
    field: "7",
    headerName: "Settle Date",
    editable: true,
  },
  {
    field: "8",
    headerName: "Quantity",
    editable: true,
  },
  {
    field: "9",
    headerName: "Commission",
    editable: true,
  },
  {
    field: "10",
    headerName: "Price",
    editable: true,
  },
  {
    field: "11",
    headerName: "Gross $",
    editable: true,
  },
  {
    field: "12",
    headerName: "Commission $",
    editable: true,
  },
  {
    field: "13",
    headerName: "Fees",
    editable: true,
  },
  {
    field: "14",
    headerName: "Net $",
    editable: true,
  },

  {
    field: "15",
    headerName: "Instrument Type",
    editable: true,
  },
  {
    field: "16",
    headerName: "Call/Put",
    editable: true,
  },
  {
    field: "17",
    headerName: "Contract Size",
    editable: true,
  },
  {
    field: "18",
    headerName: "Exp Date",
    editable: true,
  },
  {
    field: "19",
    headerName: "Strike Price",
    editable: true,
  },
  {
    field: "20",
    headerName: "Currency",
    editable: true,
  },
  {
    field: "21",
    headerName: "Transaction ID",
    editable: true,
  },
  {
    field: "22",
    headerName: "Trd Sign",
    editable: true,
  },
  {
    field: "23",
    headerName: "SEC FEE",
    editable: true,
  },
  {
    field: "24",
    headerName: "ORF FEE",
    editable: true,
  },
  {
    field: "25",
    headerName: "OCC FEE",
    editable: true,
  },
  {
    field: "26",
    headerName: "Portfolio",
    editable: true,
  },
  {
    field: "27",
    headerName: "Away Fee",
    editable: true,
  },
  {
    field: "28",
    headerName: "Custodian",
    editable: true,
  },
];
//GoldmanSachs
export const goldmanSachs_columns = [
  {
    field: "0",
    headerName: "Order number",
    editable: true,
  },
  {
    field: "1",
    headerName: "Cance correct indicator",
    editable: true,
  },
  {
    field: "2",
    headerName: "Account number or acronym",
    editable: true,
  },
  {
    field: "3",
    headerName: "Security identifier",
    editable: true,
  },
  {
    field: "4",
    headerName: "Broker",
    editable: true,
  },
  {
    field: "5",
    headerName: "Custodian",
    editable: true,
  },
  {
    field: "6",
    headerName: "Transaction type",
    editable: true,
  },
  {
    field: "7",
    headerName: "Currency code",
    editable: true,
  },
  {
    field: "8",
    headerName: "Trade date",
    editable: true,
  },
  {
    field: "9",
    headerName: "Settle date",
    editable: true,
  },
  {
    field: "10",
    headerName: "Quantity",
    editable: true,
  },
  {
    field: "11",
    headerName: "Commission",
    editable: true,
  },
  {
    field: "12",
    headerName: "Price",
    editable: true,
  },
  {
    field: "13",
    headerName: "Accrued interest",
    editable: true,
  },
  {
    field: "14",
    headerName: "Trade tax",
    editable: true,
  },

  {
    field: "15",
    headerName: "Misc money",
    editable: true,
  },
  {
    field: "16",
    headerName: "Net amount",
    editable: true,
  },
];
export const TABLE_HEAD_FUNDS_ACCOUNTS = [
  { id: "name", label: "Account Name", alignRight: false },
  { id: "value", label: "Account Number", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];
export const TABLE_HEAD_FUNDS_CUSTODIANS = [
  { id: "name", label: "Custodian", alignRight: false },
  { id: "value", label: "Account Number", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];
export const TABLE_HEAD_EQUITY = [
  { id: "Date", label: "Date", alignRight: false },
  { id: "Custodian", label: "Custodian", alignRight: false },
  { id: "Tickers", label: "Ticker", alignRight: false },
  { id: "Sharecount", label: "Share Count", alignRight: false },
  { id: "Cost", label: "Cost Per Share", alignRight: false },
  { id: "Price", label: "Price", alignRight: false },
];
export const TABLE_HEAD_OPTION = [
  { id: "Date", label: "Date", alignRight: false },
  { id: "Custodian", label: "Custodian", alignRight: false },
  { id: "Tickers", label: "Ticker", alignRight: false },
  { id: "Options", label: "Options", alignRight: false },
  { id: "StrikePrice", label: "Strike Price", alignRight: false },
  { id: "Expiry", label: "Expiry", alignRight: false },
  { id: "Contracts", label: "Contracts", alignRight: false },
];
export const TABLE_HEAD_TRADE = [
  // { id: "all",  headerName: "All", alignCenter: true, isActive: true },
  {
    field: "Actions",
    headerName: "Actions",
    alignCenter: true,
    isActive: true,
  },
  {
    field: "fundStatus",
    headerName: "Status",
    alignCenter: true,
    isActive: true,
  },
  {
    field: "id",
    headerName: "#",
  },
  {
    field: "TradeTime",
    headerName: "Trade Time",
    alignCenter: true,
    isActive: true,
  },
  {
    field: "TradeType",
    headerName: "Trade Type",
    alignCenter: true,
    isActive: true,
  },
  { field: "Pcm", headerName: "Ticker", alignCenter: true, isActive: true },
  {
    field: "ordered",
    headerName: "Ordered Shares",
    alignCenter: true,
    isActive: true,
  },
  {
    field: "working",
    headerName: "Working Shares",
    alignCenter: true,
    isActive: true,
  },
  {
    field: "tradefills",
    headerName: "A/M",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "completed",
    headerName: "Completed Shares",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "price",
    headerName: "Completion Price Per Share",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "comm",
    headerName: "Comm Per Share",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "sec",
    headerName: "SEC Price per Share",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "occ",
    headerName: "OCC Fee per Share",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "orf",
    headerName: "ORF Fee per Share",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "brokerId",
    headerName: "Broker ID",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "Ins",
    headerName: "Trade Instructions",
    alignLeft: true,
    isActive: true,
  },

  {
    field: "ordertype",
    headerName: "Order type",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "routing",
    headerName: "Order Routed",
    alignLeft: true,
    isActive: true,
  },

  { field: "currency", label: "Currency", alignLeft: true, isActive: true },
  { id: "prime", label: "Prime Broker", alignLeft: true, isActive: true },
  {
    field: "InvestmentType",
    headerName: "Investment Type",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "PositionType",
    headerName: "Position Type",
    alignLeft: true,
    isActive: true,
  },
  { field: "client", headerName: "Client ID", alignLeft: true, isActive: true },
  {
    field: "strategy",
    headerName: "Strategy",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "expiration",
    headerName: "Expiration",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "strikeprice",
    headerName: "Strike Price",
    alignLeft: true,
    isActive: true,
  },
  {
    field: "putsorcall",
    headerName: "Puts or calls",
    alignLeft: true,
    isActive: true,
  },
];
export const TABLE_HEAD_BROKER = [
  { id: "name", label: "Full Name", alignLeft: true },
  { id: "abbreviation", label: "Abbreviation", alignLeft: true },
  { id: "routerNames", label: "Router (EMSX)", alignLeft: true },

  // { id: "Option", label: "Option", alignLeft: true },
  // { id: "routerNames", label: "EMSX (Equity)", alignLeft: true },
  // { id: "emsxop", label: "EMSX (Option)", alignLeft: true },
  // { id: "ibp", label: "IB (Option)", alignLeft: true },
  // { id: "ibeq", label: "IB (Equity)", alignLeft: true },
  { id: "date", label: "Creation Date", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_WATCHLIST = [
  { id: "name", label: "Serial No #", alignLeft: true },
  { id: "stock", label: "Stock (Ticker)", alignLeft: true },
  { id: "ldcp", label: "Last Day Closing Price", alignLeft: true },
  { id: "currentPrice", label: "Current Price ", alignLeft: true },
];

export const TABLE_HEAD_FUND = [
  { id: "abbreviation", label: "Abbreviation", alignLeft: true },
  { id: "name", label: "Full Name", alignLeft: true },
  { id: "SelectedAum", label: "AUM", alignLeft: true },
  { id: "custodiansCount", label: "No Of Custodians", alignLeft: true },
  { id: "accountsCount", label: "No Of Accounts", alignLeft: true },
  { id: "brokerCount", label: "No Of Brokerage", alignLeft: true },
  { id: "creationDate", label: "Creation Date", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_MARKET_DATA = [
  { id: "vantage_name", label: "Vantage data item name", alignLeft: true },
  { id: "bloomberg_name", label: "Bloomberg Field", alignLeft: true },
  { id: "position", label: "Position Type", alignLeft: true },
  { id: "investment", label: "Investment Type", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_SIGNALS_LIST = [
  { id: "signal_name", label: "Signal Name", alignLeft: true },
  { id: "submitted_by", label: "Submitted By", alignLeft: true },
  { id: "creationDate", label: "Creation Date", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_SMA = [
  { id: "title", label: "Title", alignLeft: true },
  { id: "account", label: "Account Number", alignLeft: true },
  { id: "strategies", label: "Subscribed Strategies", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_USER = [
  { id: "name", label: "Name", alignLeft: true },
  { id: "email", label: "Email", alignLeft: true },
  { id: "organization", label: "Organization", alignLeft: true },
  { id: "tradeBlotter", label: "Trade Blotter", alignLeft: true },
  { id: "dailyPerformance", label: "Daily Performance", alignLeft: true },
  { id: "signals", label: " Signals", alignLeft: true },
  { id: "rebalancer", label: "Rebalancer", alignLeft: true },
  { id: "creationDate", label: "Creation Date", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_ROLES_MANAGEMENT = [
  { id: "role", label: "Role", alignLeft: true },
  { id: "tradeBlotter", label: "Trade Blotter", alignLeft: true },
  { id: "dailyPerformance", label: "Daily Performance", alignLeft: true },
  {
    id: "performanceDashboard",
    label: "Performance Dashboard",
    alignLeft: true,
  },
  { id: "signals", label: " Signals", alignLeft: true },
  { id: "rebalancer", label: "Rebalancer", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_STRATEGIES = [
  { id: "name", label: "Strategy Name", alignLeft: true },
  { id: "owner", label: "Owner", alignLeft: true },
  { id: "owner", label: "Permissions", alignLeft: true },
  { id: "date", label: "Last Updated Date", alignLeft: true },
  { id: "desc", label: "Description", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_TICKER = [
  { id: "creationDate", label: "Creation Date", alignLeft: true },
  { id: "name", label: "Full Name", alignLeft: true },
  { id: "abbreviation", label: "Abbreviation", alignLeft: true },
];
export const TABLE_HEAD_ROUTER = [
  { id: "name", label: "Full Name", alignLeft: true },
  { id: "aum", label: "Aum", alignLeft: true },
  { id: "date", label: "Creation Date", alignLeft: true },
];

export const TABLE_HEAD_CUSTODIAN = [
  { id: "name", label: "Full Name", alignLeft: true },
  { id: "abbreviation", label: "Abbreviation", alignLeft: true },
  { id: "date", label: "Creation Date", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_SECURITY_MASTERS = [
  { id: "ticker_symbol", label: "Ticker Symbol", alignLeft: true },
  { id: "ibkr_symbol", label: "IBKR Symbol", alignLeft: true },
  { id: "emsx_symbol", label: "EMSX Symbol", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_BLACKLISTED_TICKERS = [
  { id: "ticker", label: "Ticker", alignLeft: true },
  { id: "fund", label: "Fund", alignLeft: true },
  { id: "manager", label: "Manager", alignLeft: true },
  { id: "actions", label: "Actions", alignLeft: true },
];
export const TABLE_HEAD_PERFORMANCE = [
  { id: "serialNo", label: "Serial No", alignLeft: true },
  { id: "StrategyName", label: "Strategy Name", alignLeft: true },
  { id: "aum", label: "AUM", alignLeft: true },
  { id: "profit", label: "Profit/Loss", alignLeft: true },
];
export const TABLE_HEAD_TRADES_EXECUTED = [
  { id: "Pcm", label: "Ticker", alignLeft: true },
  { id: "TradeType", label: "Trade Type", alignLeft: true },
  { id: "ordered", label: "Shares Ordered", alignLeft: true },
  { id: "comm", label: "Shares Executed", alignLeft: true },
  { id: "brokerId", label: "Broker ID", alignLeft: true },
  { id: "prime", label: "Prime Broker", alignLeft: true },
  { id: "InvestmentType", label: "Investment Type", alignLeft: true },
];
export const TABLE_HEAD_TRADES_EQUITY = [
  { id: "date", label: "Buying Date", alignLeft: true },
  { id: "shareCount", label: "Share Count", alignLeft: true },
  { id: "cost", label: "Cost", alignLeft: true },
  { id: "profitLossWeek", label: "P(L) Weekly", alignLeft: true },
  { id: "profitLossMonth", label: "P(L) Monthly", alignLeft: true },
  { id: "profitLossQuarter", label: "P(L) Quarterly", alignLeft: true },
  { id: "profitLossYear", label: "P(L) Yearly", alignLeft: true },
  { id: "profitLossTotal", label: "P(L)", alignLeft: true },
  { id: "deltaVal", label: "P(L)%", alignLeft: true },
  { id: "strategy", label: "Strategy", alignLeft: true },
];
export const TABLE_HEAD_TRADES_EQUITY_CLOSEPOS = [
  { id: "ticker", label: "Ticker", alignLeft: true },
  { id: "shareCount", label: "Share Count", alignLeft: true },
  { id: "cost", label: "Cost", alignLeft: true },
  { id: "custodian", label: "Custodian", alignLeft: true },
  { id: "broker", label: "Broker", alignLeft: true },
];
export const TABLE_HEAD_TRADES_OPTIONS = [
  { id: "date", label: "Start Date", alignLeft: true },
  { id: "expirationDate", label: "Expiry Date", alignLeft: true },
  { id: "shareCount", label: "Contract Size", alignLeft: true },
  { id: "strikePrice", label: "Strike Price", alignLeft: true },
  { id: "option", label: "Option Type", alignLeft: true },
  { id: "delta", label: "Delta", alignLeft: true },
  {
    id: "deltaShareCount",
    label: "DA ShareCount",
    alignLeft: true,
  },
  { id: "profitLossWeek", label: "P(L) Weekly", alignLeft: true },
  { id: "profitLossMonth", label: "P(L) Monthly", alignLeft: true },
  { id: "profitLossQuarter", label: "P(L) Quarterly", alignLeft: true },
  { id: "profitLossYear", label: "P(L) Yearly", alignLeft: true },
  { id: "profitLossTotal", label: "P(L)", alignLeft: true },
  { id: "deltaVal", label: "P(L)%", alignLeft: true },
  { id: "strategy", label: "Strategy", alignLeft: true },
];

export const TABLE_HEAD_PORTFOLIO_SHORT = [
  {
    id: "short_size",
    label: "Size",
    title: "Delta Adjusted Percent Of AUM",
    alignRight: true,
  },
  {
    id: "short_deltaadjustedsharecount",
    label: "Shares",
    title: "Delta Adjusted ShareCount",
    alignRight: true,
  },
  {
    id: "short_tickerSymbol",
    label: "Ticker",
    title: "Ticker Symbol",
    alignLeft: true,
  },
  {
    id: "short_last",
    label: "Yest",
    title: "Yesterday's Closing Share Price",
    alignRight: true,
  },
  {
    id: "short_current",
    label: "Last",
    title: "Current Share Price",
    alignRight: true,
  },
  {
    id: "short_ch",
    label: "Chg",
    title: "Change in Price(Current Price-Last Price)",
    alignRight: true,
  },
  { id: "short_pL", label: "P(L)", title: "Profit/Loss", alignRight: true },
  {
    id: "short_chpercentage",
    label: "%Chg",
    title: "Percentage Change in Price",
    alignRight: true,
  },
  {
    id: "short_pl_avg_cost",
    label: "P(L) (Avg Cost)",
    title: "P(L) (Avg Cost) ",
    alignRight: true,
  },
  {
    id: "short_percentage_avg_cost",
    label: "% Chg (Avg Cost)",
    title: "% Chg (Avg Cost) ",
    alignRight: true,
  },

  {
    id: "short_cost",
    label: "Avg Cost",
    title: "Average Cost",
    alignRight: true,
  },
  {
    id: "short_tdg",
    label: "Tdg",
    title: "Profit/Loss from Trading Today",
    alignLeft: true,
  },
];
export const TABLE_HEAD_PORTFOLIO_LONG = [
  {
    id: "long_size",
    label: "Size",
    title: "Delta Adjusted Percent Of AUM",
    alignRight: true,
  },
  {
    id: "long_deltaadjustedsharecount",
    label: "Shares",
    title: "Delta Adjusted ShareCount",
    alignRight: true,
  },
  {
    id: "long_tickerSymbol",
    label: "Ticker",
    title: "Ticker Symbol",
    alignLeft: true,
  },
  {
    id: "long_last",
    label: "Yest",
    title: "Yesterday's Closing Share Price",
    alignRight: true,
  },
  {
    id: "long_current",
    label: "Last",
    title: "Current Share Price",
    alignRight: true,
  },
  {
    id: "long_ch",
    label: "Chg",
    title: "Change in Price(Current Price-Last Price)",
    alignRight: true,
  },
  { id: "long_pL", label: "P(L)", title: "Profit/Loss", alignRight: true },
  {
    id: "long_chpercentage",
    label: "%Chg",
    title: "Percentage Change in Price",
    alignRight: true,
  },
  {
    id: "long_pl_avg_cost",
    label: "P(L) (Avg Cost)",
    title: "P(L) (Avg Cost) ",
    alignRight: true,
  },
  {
    id: "long_percentage_avg_cost",
    label: "% Chg (Avg Cost)",
    title: "% Chg (Avg Cost) ",
    alignRight: true,
  },

  {
    id: "long_cost",
    label: "Avg Cost",
    title: "Average Cost",
    alignRight: true,
  },
  {
    id: "long_tdg",
    label: "Tdg",
    title: "Profit/Loss from Trading Today",
    alignLeft: true,
  },
];
export const TABLE_HEAD_SIGNAL = [
  { id: "tickers", label: "Ticker", alignCenter: true },
  { id: "weights", label: "Weighting %", alignCenter: true },
];
export const TABLE_HEAD_SIGNALS = [
  { id: "name", label: "Ticker", alignCenter: true },
  { id: "value", label: "Weighting %", alignCenter: true },
  { id: "value", label: "L/S", alignCenter: true, widthIdentifier: true },
];
export const TABLE_HEAD_SIGNALS_SUMMARIES = [
  { id: "name", label: "Ticker", alignCenter: true },
  { id: "perf", label: "Perf.", alignCenter: true },
  { id: "value", label: "Weighting %", alignCenter: true },
  { id: "value", label: "L/S", alignCenter: true, widthIdentifier: true },
];
export const TABLE_HEAD_SIGNALS_PERFORMANCE = [
  { id: "symbol", label: "Ticker", alignCenter: true },
  { id: "PercentChangeDay", label: "Intraday", alignCenter: true },
  { id: "PercentChangeWeek", label: "WTD", alignCenter: true },
  { id: "PercentChangeMonth", label: "MTD", alignCenter: true },
  { id: "PercentChangeQuarter", label: "QTD", alignCenter: true },
  { id: "marketCap", label: "Market Cap", alignCenter: true },
];
export const TABLE_HEAD_SIGNALS_PERFORMANCE_MARKETCAP = [
  { id: "myCap", label: "Market Cap", alignCenter: true },
  { id: "cut", label: "Cut", alignCenter: true },
  { id: "long", label: "Long", alignCenter: true },
  { id: "short", label: "Short", alignCenter: true },
  { id: "bias", label: "Bias", alignCenter: true },
];
export const TABLE_HEAD_SIGNALS_PERFORMANCE_SECTORCAP = [
  { id: "sector", label: "Sector", alignCenter: true },
  { id: "long", label: "Long", alignCenter: true },
  { id: "short", label: "Short", alignCenter: true },
  { id: "bias", label: "Bias", alignCenter: true },
];
export const TABLE_HEAD_SIGNALS_PERFORMANCE_SUMMARY = [
  { id: "position", label: "Position", alignCenter: true },
  { id: "PercentChangeDay", label: "Intraday", alignCenter: true },
  { id: "PercentChangeWeek", label: "WTD", alignCenter: true },
  { id: "PercentChangeMonth", label: "MTD", alignCenter: true },
  { id: "PercentChangeQuarter", label: "QTD", alignCenter: true },
];
export const TABLE_HEAD_EXISTING = [
  { id: "tickersExisting", label: "Ticker", alignCenter: true },
  { id: "weightsExisting", label: "Weighting", alignCenter: true },
  { id: "shares", label: "Trade Order Shares", alignCenter: true },
];
export const TABLE_HEAD_Locates = [
  { id: "tickerslocates", label: "Ticker", alignCenter: true },
  { id: "orginal", label: "Original Weighting", alignCenter: true },
  { id: "new", label: "New Weighting", alignCenter: true },
];
export const TABLE_HEAD_SIGNAL_LOCATES = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "investment", label: "Security Type", alignCenter: true },
  { id: "marketData", label: "Market Data", alignCenter: true },
];
export const TABLE_HEAD_FUND_REBALANCE_EXISTING = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "shareCount", label: "Share Count", alignCenter: true },
];
export const TABLE_HEAD_FUND_REBALANCE_EXISTING2 = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "weight", label: "Weight", alignCenter: true },
  { id: "shareCount", label: "Share Count", alignCenter: true },
  { id: "type", label: "Trade Type", alignCenter: true },
  { id: "marketPrice", label: "Market Price", alignCenter: true },
];
export const TABLE_HEAD_FUND_REBALANCE_LOCATES = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "shareCount", label: "Locates Required", alignCenter: true },
  { id: "available", label: "Locates Available", alignCenter: true },
];
export const TABLE_HEAD_FUND_REBALANCE_BLACKLIST = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "reason", label: "Reason", alignCenter: true },
];
export const TABLE_HEAD_FINAL = [
  { id: "tickers", label: "Ticker", alignCenter: true },
  { id: "weights", label: "Target Weighting", alignCenter: true },
];
export const Ammended_List = [
  { id: "sno", label: "#", alignCenter: true },
  { id: "tickers", label: "Ticker", alignCenter: true },
  { id: "idealweights", label: "Ideal Weight", alignCenter: true },
  { id: "currentweight", label: "Current Weight", alignCenter: true },
  { id: "variance", label: "Variance", alignCenter: true },
  { id: "sharecounts", label: "ShareCount", alignCenter: true },
];
export const TABLE_HEAD_VANTAGE = [
  { id: "fundName", label: "Fund Name", alignCenter: true },
  { id: "strategyName", label: "Strategy Name", alignCenter: true },
  { id: "aum", label: "AUM", alignCenter: true },
  { id: "pL", label: "P(L)Today", alignCenter: true },
  { id: "plLong", label: "P(L)Today Long", alignCenter: true },
  { id: "plShort", label: "P(L)Today Short", alignCenter: true },
  {
    id: "sizeLong",
    label: "% AUM DA Long",
    alignCenter: true,
  },
  {
    id: "sizeShort",
    label: " % AUM DA Short",
    alignCenter: true,
  },
  { id: "longCapital", label: "Long Capital", alignCenter: true },
  { id: "shortCapital", label: "Short Capital", alignCenter: true },
  {
    id: "longPerformance",
    label: "Long Performance",
    alignCenter: true,
  },
  {
    id: "shortPerformance",
    label: "Short Performance",
    alignCenter: true,
  },
  { id: "plPer", label: "P(L)% Today", alignCenter: true },
  { id: "pLWeek", label: "P(L) Week", alignCenter: true },
  { id: "pLMonth", label: "P(L) Month", alignCenter: true },
  { id: "pLQuarter", label: "P(L) Quarter", alignCenter: true },
  { id: "pLYear", label: "P(L) Year", alignCenter: true },
];
export const TABLE_HEAD_FUNDPNL = [
  { id: "icon", label: "", alignCenter: true },
  { id: "fundName", label: "Fund Name", alignCenter: true },
  { id: "dailyPL", label: "$ Daily P&L", alignCenter: true },
  { id: "dailyPL%", label: "$ Daily P&L %", alignCenter: true },
  { id: "longDelta", label: "Long Delta %", alignCenter: true },
  { id: "shortDelta", label: "Short Delta %", alignCenter: true },
  { id: "netDelta", label: "Net Delta %", alignCenter: true },
];
export const TABLE_HEAD_LONGSHORT = [
  { id: "icon", label: "", alignCenter: true },
  { id: "netDelta", label: "$ Net", alignCenter: true },
  { id: "gross", label: "$ Gross", alignCenter: true },
  { id: "grosslong", label: " $Long", alignCenter: true },
  { id: "shortDelta", label: "$ Short", alignCenter: true },
  { id: "longDelta", label: "% AUM ", alignCenter: true },
  { id: "longDeltaTodays", label: "Today's Trades", alignCenter: true },
  { id: "options", label: "Options", alignCenter: true },
  { id: "daily", label: "$ Daily PnL", alignCenter: true },
  { id: "daily", label: "Daily Return", alignCenter: true },
];
export const TABLE_HEAD_COLLAPSED = [
  { id: "asset", label: "Asset", alignCenter: true },
  { id: "purchaseDate", label: "Purchase Date", alignCenter: true },
  { id: "daysHeld", label: "Days Held", alignCenter: true },
  { id: "longShort", label: "Long/Short", alignCenter: true },
  { id: "position", label: "Position", alignCenter: true },
  { id: "grossPrice", label: "Gross Price", alignCenter: true },
  { id: "marketValue", label: "Market Value", alignCenter: true },
  { id: "profitLoss", label: "Profit (loss)", alignCenter: true },
  { id: "emp1", label: "-", alignCenter: true },
  { id: "emp2", label: "-", alignCenter: true },
  { id: "emp3", label: "-", alignCenter: true },
];
export const TABLE_HEAD_COLLAPSED2 = [
  { id: "tickSym", label: "Ticker Symbol", alignCenter: true },
  { id: "quant", label: "Quantity", alignCenter: true },
  { id: "lp", label: "Last Price", alignCenter: true },
  { id: "pl", label: "Profit/(Loss)", alignCenter: true },
  { id: "expr", label: "$ Exposure", alignCenter: true },
  { id: "deltaexpr", label: "% Exposure", alignCenter: true },
];

export const Unallowed_List = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "weightings", label: "Weight", alignCenter: true },
  { id: "sharecount", label: "Share Count", alignCenter: true },
  { id: "assignedto", label: "Assigned To", alignCenter: true },
];

export const Black_List = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "weightings", label: "Weight", alignCenter: true },
];
export const strategy_List = [
  { id: "strategy", label: "Strategies", alignCenter: true },
];
export const pereference_List = [
  { id: "broker_preference", label: "Broker Preference", alignCenter: true },
  {
    id: "custodian_preference",
    label: "Custodian Preference",
    alignCenter: true,
  },
];
export const Existing_List = [
  { id: "allocation", label: "Allocation to Strategy", alignCenter: true },
  { id: "percentLong", label: "Percent Long", alignCenter: true },
  { id: "percentShort", label: "Percent Short", alignCenter: true },
];
export const Existing_List_Target = [
  { id: "existing", label: "Allocation to Strategy", alignLeft: true },
  { id: "allocation", label: "Allocation to Strategy", alignLeft: true },
  { id: "plong", label: "Percent Long", alignLeft: true },
  { id: "pshort", label: "Percent Short", alignLeft: true },
];
export const Fund_aum_List = [
  { id: "totalfund", label: "Total for Fund", alignCenter: true },
  { id: "Epercent", label: "Percent", alignCenter: true },
  { id: "Eamount", label: "Amount", alignCenter: true },
  { id: "Tpercent", label: "Percent", alignCenter: true },
  { id: "Tamount", label: "Amount", alignCenter: true },
];

export const Signal_List = [
  { id: "newsignal", label: "New Signals List", alignCenter: true },
  { id: "addsignal", label: "Add Signal", alignCenter: true },
];

export const New_Trade_Order = [
  { id: "ticker", label: "Ticker", alignCenter: true },
  { id: "tradetype", label: "Trade Type", alignCenter: true },
  { id: "sharesorder", label: "Shares Ordered", alignCenter: true },
  { id: "shareexecuted", label: "Share Executed", alignCenter: true },
  { id: "brokerid", label: "Broker Id", alignCenter: true },
  { id: "primebroker", label: "Prime Broker", alignCenter: true },
  { id: "investmenttype", label: "Investment Type", alignCenter: true },
];
export function inputChecker(string) {
  let ticker = {
    symbol: "",
    currency: "USD",
    country: "US",
    investment: "equity",
  };
  let isValid = true;
  const resultArray = string.split(/\s+/);
  const lastItem = resultArray[resultArray.length - 1];
  const isBloomberg =
    lastItem.toLowerCase() === "options" || lastItem.toLowerCase() === "equity";

  if (isBloomberg) {
    ticker.investment = lastItem.toLowerCase();
    const secondLastItem = resultArray[resultArray.length - 2];
    const isValidCountryCode = /^[a-zA-Z]{2}$/.test(secondLastItem);
    if (isValidCountryCode) {
      ticker.country = secondLastItem;
      const firstItem = resultArray[0];
      const thirdLastItem = resultArray[resultArray.length - 3];
      if (firstItem != thirdLastItem) {
        isValid = false;
        ticker.symbol = firstItem;
      } else {
        if (/^[a-zA-Z0-9]+$/.test(firstItem)) {
          ticker.symbol = firstItem;
        } else if (/[/]/.test(firstItem)) {
          const [symbol, stock] = firstItem.split(/[/]/);
          ticker.stock = stock;
          ticker.symbol = symbol;
        } else {
          isValid = false;
        }
      }
    } else {
      isValid = false;
    }
  } else {
    if (resultArray.length == 1) {
      if (/[./]/.test(lastItem)) {
        const [symbol, stock] = lastItem.split(/[./]/);
        ticker.symbol = symbol;
        ticker.stock = stock;
      } else if (/^[a-zA-Z0-9]+$/.test(lastItem)) {
        ticker.symbol = lastItem;
      } else {
        isValid = false;
      }
    } else {
      if (/^[a-zA-Z]$/.test(lastItem)) {
        ticker.stock = lastItem;
      } else {
        isValid = false;
      }
    }
  }

  return { ticker, isValid };
}
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const Tablecell = styled(TableCell)(({ theme }) => ({
  color: "#000",
  fontSize: "12px",
  padding: "0px",
  border: "0.1px solid #dadada",
}));
const TablecellPerformance = styled(TableCell)(({ theme }) => ({
  fontSize: "12px",
  padding: "1px 2px 1px 5px",
}));

export const Tablecolcell = styled(TableCell)(({ theme }) => ({
  color: "#000",
  fontSize: "12px",
  padding: "0px",
  border: "0.1px solid #dadada",
  background: theme.palette.blue[100],
}));

export const BoxHold = styled(Box)(({ theme }) => ({
  width: "100%",
  background: "rgb(242 242 242)",
  borderRadius: 4,
  display: "flex",
  flexDirection: "column",
  padding: "5px",
  margin: "5px",
}));
export const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 50,
  display: "flex",
  justifyContent: "space-between",
}));
export const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.primary.light,
  margin: 2,
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  borderRadius: 3,
  background: "#FFF",
  fontSize: "15px",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    boxShadow:
      "0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)",
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: "#A9A9A9",
  },
}));
export const DummyFundData = [
  {
    serialNo: "12777D68",
    strategyName: "UpFactor_Long",
    Aum: "146,890,89",
    profit: "12%",
  },
  {
    serialNo: "12777D68",
    strategyName: "UpFactor_Short",
    Aum: "146,890,89",
    profit: "12%",
  },
  {
    serialNo: "7777D68",
    strategyName: "STOCROC",
    Aum: "1890,890,89",
    profit: "50%",
  },
];
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#5A5A5A",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
export function isDateOlderThan5Minutes(dateString, threshold) {
  if (dateString === undefined || threshold === undefined) {
    return true;
  }

  const currentDate = new Date();
  const givenDate = new Date(dateString);
  const timeDifference = currentDate - givenDate;
  const minutesDifference = timeDifference / (1000 * 60);
  return minutesDifference > threshold;
}

export const processData = (strategies, strategyValObj) => {
  if (strategyValObj && strategies) {
    const strategy = strategies.find(
      (strategy) => strategy.strategyId === strategyValObj
    );

    if (strategy && strategy.signals) {
      const { weights_keys } = strategy.signals;
      const keys = Object.keys(weights_keys);

      if (keys.length > 0) {
        const tickers = keys.map((key) => ({
          symbol: weights_keys[key]?.symbol,
          currency: weights_keys[key]?.currency,
          investment: weights_keys[key]?.investment,
          exchange: weights_keys[key]?.exchange,
        }));

        try {
          window.clientSocks.send(
            JSON.stringify({
              action: "market_data",
              data: {
                threshold: 120,
                request: true,
                fields: [4, 5, 6, 7, 8, 11],
                tickers: tickers,
              },
            })
          );
        } catch (error) {
          console.error("Error sending WebSocket message:", error);
        }
      }
    }
  }
};

export const fetchTodaysSymbols = (trades) => {
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  const endOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );

  const startOfTodayTimestamp = startOfToday.getTime();
  const endOfTodayTimestamp = endOfToday.getTime();

  let todaysTrades = trades.filter((trade) => {
    const lastOrderDate = trade.orders[trade.orders.length - 1].date;
    return (
      lastOrderDate >= startOfTodayTimestamp &&
      lastOrderDate <= endOfTodayTimestamp
    );
  });

  let uniqueSymbols = new Set();

  todaysTrades.map((trade) => {
    const lastOrder = trade.orders[trade.orders.length - 1];
    const symbol = lastOrder.ticker.symbol.toLowerCase();

    if (!uniqueSymbols.has(symbol)) {
      uniqueSymbols.add(symbol);
    }
  });

  const result = Array.from(uniqueSymbols).map((symbol) => {
    return {
      investment: 4,
      symbol: symbol,
      currency: 7,
      exchange: 4,
    };
  });

  return result;
};

export const calculateTdg = (data, marketData, strategy) => {
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  const endOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59,
    999
  );

  const startOfTodayTimestamp = startOfToday.getTime();
  const endOfTodayTimestamp = endOfToday.getTime();

  let todaysTrades = data.filter((trade) => {
    const lastOrderDate = trade.orders[trade.orders.length - 1].date;
    return (
      lastOrderDate >= startOfTodayTimestamp &&
      lastOrderDate <= endOfTodayTimestamp
    );
  });

  const rawTrades = todaysTrades
    .map((trade) => {
      const lastOrder = trade.orders[trade.orders.length - 1];
      const symbol = lastOrder.ticker.symbol.toLowerCase();
      let completedShares, price;

      const distribute = Object.entries(lastOrder?.distribution || {}).reduce(
        (acc, [key, value]) => {
          const [account, fund, _] = key.split("#");
          const strategySplit = key.split("#0#");
          const strategy = strategySplit.length > 1 ? strategySplit[1] : "";

          if (!acc[fund]) {
            acc[fund] = [];
          }

          acc[fund].push({
            shareCount: Number(value),
            account: account,
            strategy: strategy,
          });

          return acc;
        },
        {}
      );

      const distribution = distribute;

      const firstKeyInDistribution = Object.keys(distribution)[0];

      const distributionStrategy =
        distribution[firstKeyInDistribution][0]?.strategy;

      if (strategy) {
        if (distributionStrategy != strategy) return null;
      }

      if (trade.fill) {
        completedShares = parseFloat(trade.fill.shareCount.toFixed(4));
        price = parseFloat(trade.fill.sharePrice.toFixed(4));
      } else if (trade.manuals && trade.manuals.length > 0) {
        const lastManual = trade.manuals.slice(-1)[0];
        completedShares = parseFloat(lastManual.shareCount.toFixed(4));
        price = parseFloat(lastManual.sharePrice.toFixed(4));
      } else {
        return null;
      }

      const action = lastOrder.action; // Capture the action type
      const position = lastOrder.position; // Capture the position

      if (
        !((action === 0 && position === 0) || (action === 3 && position === 1))
      ) {
        return null;
      }

      const marketInfo = marketData.find(
        (item) => item.ticker === symbol.toLowerCase()
      );
      if (!marketInfo) {
        return null;
      }

      const investmentCost = completedShares * price;
      const currentMarketValue = completedShares * marketInfo.current;
      let pnl;

      pnl = currentMarketValue - investmentCost;

      return {
        symbol,
        action,
        completedShares,
        price,
        currentMarketPrice: marketInfo.current,
        PnL: pnl,
        position,
      };
    })
    .filter((trade) => trade !== null);

  // Aggregate results for the same ticker
  const aggregatedResults = rawTrades.reduce((acc, trade) => {
    if (acc[trade.symbol]) {
      acc[trade.symbol].completedShares += trade.completedShares;
      acc[trade.symbol].PnL += trade.PnL;
      acc[trade.symbol].totalPrice += trade.price * trade.completedShares; // For averaging
      acc[trade.symbol].count += trade.completedShares; // Count for average calculation
    } else {
      acc[trade.symbol] = {
        position: trade.position,
        symbol: trade.symbol,
        completedShares: trade.completedShares,
        totalPrice: trade.price * trade.completedShares,
        count: trade.completedShares,
        PnL: trade.PnL,
        currentMarketPrice: trade.currentMarketPrice,
      };
    }
    return acc;
  }, {});

  return Object.values(aggregatedResults).map((trade) => ({
    symbol: trade.symbol,
    position: trade.position,
    completedShares: trade.completedShares,
    price: (trade.totalPrice / trade.count).toFixed(4),
    PnL: trade.PnL.toFixed(4),
    currentMarketPrice: trade.currentMarketPrice,
  }));
};

export const signalsData = (signalsPerformance, strategies, strategyValObj) => {
  if (signalsPerformance && signalsPerformance.length > 0) {
    if (strategyValObj && strategies) {
      const strategy = strategies.find(
        (strategy) => strategy.strategyId === strategyValObj
      );

      if (strategy && strategy.signals) {
        const { weights_keys, weights_values } = strategy.signals;
        const keys = Object.keys(weights_keys);

        if (keys.length > 0) {
          const combinedArray = keys.map((key) => ({
            key: key,
            symbol: weights_keys[key]?.symbol,
            value: weights_values[key] * 100,
          }));

          const combinedSymbols = combinedArray.map((item) => item.symbol);

          return signalsPerformance
            .filter((item) => combinedSymbols.includes(item.symbol))
            .map((item) => {
              const combinedItem = combinedArray.find(
                (combined) => combined.symbol === item.symbol
              );

              return {
                notRecent: isDateOlderThan5Minutes(item?.expiration, 120),
                PercentChangeDay: parseFloat(item?.PercentChangeDay),
                PercentChangeMonth: parseFloat(item?.PercentChangeMonth),
                PercentChangeQuarter: parseFloat(item?.PercentChangeQuarter),
                PercentChangeWeek: parseFloat(item?.PercentChangeWeek),
                marketCap: parseFloat(item?.marketCap),
                symbol: item?.symbol,
                expiration: item?.expiration,
                sector: item?.sector,
                value: parseFloat(combinedItem?.value),
              };
            });
        }
      }
    }
  }
  return [];
};
