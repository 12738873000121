import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
} from "../../../../../../Methods";

const ExistingFundRebalance = ({ existingArray, tableHeader }) => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const filteredUsers = applySortFilter(
      existingArray,
      getComparator(order, orderBy)
    );
    setData(filteredUsers);
  }, [order, orderBy, existingArray]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "1px",
          width: "80%",
          marginRight: 1,
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: "18px", mb: 1 }}>
          Existing
        </Typography>
        <Box
          sx={{
            maxHeight: "350px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "3px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c0c0c0",
              borderRadius: "10px",
              minHeight: "2px",
            },
          }}
        >
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.ticker}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      color: row?.weight > 0 ? "green" : "red",
                    }}
                  >
                    {row?.weight < 0
                      ? `(${Math.abs(row?.weight)})`
                      : `${row?.weight}%`}
                  </Tablecell>

                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row?.shareCount < 0
                      ? `(${Math.abs(row?.shareCount)})`
                      : `${row?.shareCount}`}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default ExistingFundRebalance;
