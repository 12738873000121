import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
} from "../../../../../../Methods";
import MarketDataUnavailablePopup from "../../../../../..//Popup/MarketDataUnavailablePopup";

const RebalanceTrades = ({ dummy, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    const filteredUsers = applySortFilter(dummy, getComparator(order, orderBy));
    setData(filteredUsers);
  }, [order, orderBy, dummy]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Typography sx={{ mb: 1, fontWeight: "bold", fontSize: "20px" }}>
          All Rebalancer Trades
        </Typography>

        <Alert
          severity="info"
          sx={{
            fontSize: "12px",
            textAlign: "right",
            textDecoration: "none",
           my:1
          }}
        >
          Weights adjusted by Rebalancer
        </Alert>

        <Box
          sx={{
            maxHeight: "350px", // Set max height for the scrollable area
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <Table
            size="small"
            stickyHeader
            aria-label="a dense table"
            sx={{
              borderCollapse: "collapse",
              border: "solid 1px #eee",
              marginTop: "-1px",
            }}
          >
            <VantageListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHeader}
              noStatus={true}
              customizeFont={12}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {data?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:hover": {
                      background: theme.palette.grey[200],
                      cursor: "pointer",
                    },
                  }}
                >
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.ticker}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      color: row?.weight > 0 ? "green" : "red",
                    }}
                  >
                    {row.weight}%
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row?.shareCount}
                  </Tablecell>
                  <Tablecell
                    align="left"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "10px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    {row.type}
                  </Tablecell>
                  <Tablecell
                    align="right"
                    style={{
                      fontSize: "12px",
                      paddingLeft: "5px",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                  >
                    ${row.marketPrice}
                  </Tablecell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default RebalanceTrades;
