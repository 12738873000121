import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_HEAD_FUND_REBALANCE_EXISTING2 } from "../../../../../Methods";
import { dummyData } from "../../../../../dummyData";
import RebalanceDetailedInfo from "../../../RebalanceDetailedInfo";
import RebalanceTrades from "./components/RebalanceTrades";

const FundRebalanceStep3 = ({ onNext, onPrevious, sleeve }) => {
  const [existingArray, setExistingArray] = useState([]);
  const [newArray, setNewArray] = useState([]);
  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <RebalanceDetailedInfo
                sleeve={sleeve}
                setExistingArray={setExistingArray}
                setNewArray={setNewArray}
              />
            </div>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="grey"
                startIcon={<ArrowBackIcon />}
                onClick={onPrevious}
                sx={{ width: "150px", height: "40px" }}
              >
                BACK
              </Button>

              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "300px", height: "40px" }}
              >
                Stage Rebalancer Orders
              </Button>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "40%",
              justifyContent: "space-between",
            }}
          >
            {dummyData && TABLE_HEAD_FUND_REBALANCE_EXISTING2 ? (
              <RebalanceTrades
                dummy={dummyData}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_EXISTING2}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FundRebalanceStep3;
