import React, { useEffect, useState } from "react";
import { Container, Grid, Button, Box, Alert } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TABLE_HEAD_FUND_REBALANCE_LOCATES } from "../../../../../Methods";
import Locates from "./components/Locates";
import LocatesNotFoundPopup from "../../../../../Popup/LocatesNotFoundPopup";
import { useDispatch, useSelector } from "react-redux";
import { stageThreeOfRebalance } from "../../../../../../Redux/Actions/todoActions";

const FundRebalanceStep2 = ({ setCurrentStep, onPrevious }) => {
  // const {tickerTrades}=useSelector((state)=>state.todos)
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const [locatesData, setLocatesData] = useState([]);

  const tickerTrades = [
    { id: 1, ticker: "TSLA", shareCount: 10 },
    { id: 2, ticker: "APPL", shareCount: 20 },
    { id: 3, ticker: "PEP", shareCount: 9 },
    { id: 4, ticker: "MSFT", shareCount: 10 },
  ];

  useEffect(() => {
    if (tickerTrades && tickerTrades.length > 0 && locatesData.length === 0) {
      const updatedData = tickerTrades.map((trade) => ({
        ...trade,
        available: 0,
      }));
      setLocatesData(updatedData);
    }
  }, [tickerTrades, locatesData]);

  const handleFindLocatesClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const onNext = () => {
    if (locatesData && locatesData.length > 0) {
      dispatch(stageThreeOfRebalance(locatesData));
    }

    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Container disableGutters maxWidth="100%" sx={{ marginTop: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                onClick={handleFindLocatesClick}
                sx={{
                  width: "170px",
                  height: "40px",
                  fontSize: "12px",
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": { backgroundColor: "darkgray" },
                }}
              >
                Find Locates
              </Button>
            </div>
            <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="grey"
                startIcon={<ArrowBackIcon />}
                onClick={onPrevious}
                sx={{ width: "150px", height: "40px" }}
              >
                BACK
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={onNext}
                sx={{ width: "150px", height: "40px" }}
              >
                Next
              </Button>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "34.5%",
              justifyContent: "space-between",
            }}
          >
            <Alert severity="info">
              Locates Available column is editable for the custodians which
              don't have direct API integration.
            </Alert>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "35%",
              justifyContent: "space-between",
            }}
          >
            {locatesData && locatesData.length > 0 ? (
              <Locates
                tickerTrades={locatesData}
                setLocatesData={setLocatesData}
                tableHeader={TABLE_HEAD_FUND_REBALANCE_LOCATES}
              />
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Grid>
      </Grid>

      <LocatesNotFoundPopup open={popupOpen} onClose={handleClosePopup} />
    </Container>
  );
};

export default FundRebalanceStep2;
