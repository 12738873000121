import React, { useState, useEffect } from "react";
import {
  Table,
  TableRow,
  TableBody,
  useTheme,
  Box,
  TextField,
} from "@mui/material";
import VantageListHead from "../../../../../../ListHead/SignalList";
import {
  Tablecell,
  applySortFilter,
  getComparator,
} from "../../../../../../Methods";

const Blacklisted = ({ blacklist, setBlacklistData, tableHeader }) => {
  const theme = useTheme();
  const [data, setData] = useState(blacklist);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("ticker");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAvailableChange = (e, index) => {
    const { value } = e.target;
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, reason: value } : row
    );
    setData(updatedData);
    setBlacklistData(updatedData);
  };

  useEffect(() => {
    setData(blacklist);
  }, [blacklist]);

  useEffect(() => {
    const filteredData = applySortFilter(data, getComparator(order, orderBy));
    setData(filteredData);
    setBlacklistData(filteredData);
  }, [order, orderBy, setBlacklistData]);

  return (
    <>
      <Box
        sx={{
          border: `100px ${theme.palette.lightGrey.tableHeader}`,
          borderRadius: "4px",
          marginTop: "2px",
          width: "100%",
          marginRight: 1,
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            border: "solid 1px #eee",
            marginTop: "-1px",
          }}
        >
          <VantageListHead
            order={order}
            orderBy={orderBy}
            headLabel={tableHeader}
            noStatus={true}
            customizeFont={12}
            rowCount={data.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:hover": {
                    background: theme.palette.grey[200],
                    cursor: "pointer",
                  },
                }}
              >
                <Tablecell
                  align="left"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  {row.ticker}
                </Tablecell>

                <Tablecell
                  align="left"
                  style={{
                    fontSize: "12px",
                    paddingLeft: "5px",
                    borderLeft: "none",
                    borderRight: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    value={row.reason}
                    onChange={(e) => handleAvailableChange(e, index)}
                    size="small"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "12px",
                        fontWeight: "500",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      },
                    }}
                  />
                </Tablecell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Blacklisted;
