import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import Item from "../Item";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSelector } from "react-redux";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import ContactsIcon from "@mui/icons-material/Contacts";
import AddHomeIcon from "@mui/icons-material/AddHome";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BarChartIcon from "@mui/icons-material/BarChart";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import RouterIcon from "@mui/icons-material/Router";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LoopIcon from "@mui/icons-material/Loop";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CommitIcon from "@mui/icons-material/Commit";
import SecurityIcon from "@mui/icons-material/Security";
import ListIcon from "@mui/icons-material/List";

const Sidebar = () => {
  const { managers } = useSelector((state) => state.todos);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHide, setisHide] = useState(true);
  const theme = useTheme();

  // const handleClick = (title) => {
  //   if (title === "Performance Dashboard" || title === "Daily Performance") {
  //     setIsCollapsed(true);
  //   } else {
  //     setIsCollapsed(!isCollapsed);
  //   }
  // };
  const innerItemStyles = {
    padding: "1px 10px 1px 13px !important",
    paddingLeft: isCollapsed ? "30px" : "10px",
  };
  return (
    <>
      <Box
        sx={{
          "& .pro-sidebar": {
            width: "250px !important",
            minWidth: "250px !important",
          },
          "& .pro-sidebar.collapsed ": {
            minWidth: "60px !important",
            width: "60px !important",
          },
          "& .pro-sidebar-inner": {
            backgroundColor: theme.palette.grey[700],
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
            paddingLeft: "10px",
          },
          "& .pro-inner-item": innerItemStyles,
          "& .pro-menu-item.active": {
            background: theme.palette.grey[600],
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Menu iconShape="square">
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 10px 0",
                color: theme.palette.grey[100],
              }}
            >
              {!isCollapsed && (
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon sx={{ color: theme.palette.grey[100] }} />
                  </IconButton>
                  <Typography
                    variant="h6"
                    fontSize="20px"
                    sx={{ ml: "5px" }}
                    color={theme.palette.grey[100]}
                  >
                    VANTAGE
                  </Typography>
                </Box>
              )}
            </MenuItem>

            <Box
              className="custom-scrollbar"
              sx={{
                height: "calc(110vh - 220px)",
                overflowY: "auto",
                minHeight: "100px",
              }}
            >
              {managers && managers.length > 0 ? (
                Object.keys(managers[0].funds).length === 1 ? (
                  <div>
                    <Box padding={isCollapsed ? undefined : "0px 10px"}>
                      <Item
                        title="Daily Performance"
                        to="/portfolio"
                        isCollapse={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        icon={
                          <DashboardIcon
                            sx={{ height: "17px", width: "17px" }}
                          />
                        }
                      />
                      <Item
                        title="Performance Dashboard"
                        to="/vantage"
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        icon={
                          <ContactsIcon
                            sx={{ height: "17px", width: "17px" }}
                          />
                        }
                      />
                    </Box>
                  </div>
                ) : (
                  <>
                    <div>
                      <Box padding={isCollapsed ? undefined : "0px 10px"}>
                        {/* <Item
                    title="Dashboard"
                    to="/dashboard"
                    icon={
                      <DashboardIcon sx={{ height: "17px", width: "17px" }} />
                    }
                  /> */}

                        <Item
                          title="Performance Dashboard"
                          to="/vantage"
                          // onClick={() => handleClick("Performance Dashboard")}
                          isCollapsed={isCollapsed}
                          setIsCollapsed={setIsCollapsed}
                          icon={
                            <ContactsIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Daily Performance"
                          to="/portfolio"
                          // onClick={() => handleClick("Daily Performance")}
                          isCollapse={isCollapsed}
                          setIsCollapsed={setIsCollapsed}
                          icon={
                            <DashboardIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        {/* <Item
                    title="Market Data"
                    to="/market"
                    isCollapse={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    icon={
                      <AddBusinessIcon sx={{ height: "17px", width: "17px" }} />
                    }
                  /> */}

                        <Item
                          title="Trade Blotter v2.0"
                          to="/tradesV2"
                          icon={
                            <ModelTrainingIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Trade Blotter"
                          to="/trades"
                          icon={
                            <LoopIcon sx={{ height: "17px", width: "17px" }} />
                          }
                        />
                        <Item
                          title="Watch List"
                          to="/watchlist"
                          icon={
                            <SummarizeIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Signals"
                          to="/signalsInput"
                          icon={
                            <SettingsInputComponentIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Historical Performance"
                          to="/history"
                          icon={
                            <WorkHistoryIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Fund Allocator"
                          to="/fundAllocator"
                          icon={
                            <StorefrontIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                        <Item
                          title="Rebalancer"
                          to="/rebalancer"
                          icon={
                            <BarChartIcon
                              sx={{ height: "17px", width: "17px" }}
                            />
                          }
                        />
                      </Box>

                      <Box>
                        <MenuItem
                          onClick={() => setisHide(!isHide)}
                          icon={
                            !isHide ? (
                              <ArrowCircleUpOutlinedIcon />
                            ) : (
                              <ArrowCircleDownOutlinedIcon />
                            )
                          }
                        >
                          <Typography
                            sx={{ paddingLeft: "9px", fontWeight: "bold" }}
                            color={theme.palette.grey[100]}
                          >
                            Entities
                          </Typography>
                        </MenuItem>
                      </Box>

                      {isHide ? (
                        <Box padding={isCollapsed ? undefined : "0px 10px"}>
                          <Item
                            title="Broker"
                            to="/broker"
                            icon={
                              <AddHomeIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="Custodian"
                            to="/custodian"
                            icon={
                              <BusinessCenterIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="Funds"
                            to="/funds"
                            icon={
                              <DomainAddIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="Strategies"
                            to="/strategies"
                            icon={
                              <BarChartIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="Accounting"
                            to="/accounting"
                            icon={
                              <AccountBalanceIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="OMS"
                            to="/routers"
                            icon={
                              <RouterIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="Users"
                            to="/users"
                            icon={
                              <GroupIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="Security Master"
                            to="/security"
                            icon={
                              <SecurityIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="Blacklisted Tickers"
                            to="/blacklistedTickers"
                            icon={
                              <ListIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="SMA Integration"
                            to="/sma"
                            icon={
                              <SensorOccupiedIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                          <Item
                            title="SMA Performance"
                            to="/smaPerformance"
                            icon={
                              <CommitIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="Testing"
                            to="/testing"
                            icon={
                              <GroupIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />

                          <Item
                            title="Initials"
                            to="/initials"
                            icon={
                              <GroupIcon
                                sx={{ height: "17px", width: "17px" }}
                              />
                            }
                          />
                        </Box>
                      ) : null}
                    </div>
                  </>
                )
              ) : (
                <div>
                  <Box padding={isCollapsed ? undefined : "0px 10px"}>
                    <Item
                      title="Daily Performance"
                      to="/portfolio"
                      isCollapse={isCollapsed}
                      setIsCollapsed={setIsCollapsed}
                      icon={
                        <DashboardIcon sx={{ height: "17px", width: "17px" }} />
                      }
                    />
                    {/* <Item
                      title="Trade Blotter"
                      to="/trades"
                      icon={<LoopIcon sx={{ height: "17px", width: "17px" }} />}
                    />
                    <Item
                      title="Trade Blotter v2.0"
                      to="/tradesV2"
                      icon={
                        <ModelTrainingIcon
                          sx={{ height: "17px", width: "17px" }}
                        />
                      }
                    /> */}
                    <Item
                      title="Signals"
                      to="/signalsInput"
                      icon={
                        <SettingsInputComponentIcon
                          sx={{ height: "17px", width: "17px" }}
                        />
                      }
                    />
                    <Item
                      title="Strategies"
                      to="/strategies"
                      icon={
                        <BarChartIcon sx={{ height: "17px", width: "17px" }} />
                      }
                    />
                  </Box>
                </div>
              )}
            </Box>
          </Menu>
        </ProSidebar>
        <Box></Box>
      </Box>
    </>
  );
};

export default Sidebar;
