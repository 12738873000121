import React, { useState } from "react";
import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import FundRebalance from "./RebalancingInfo/FundRebalance";
import SleeveReblance from "./RebalancingInfo/SleeveRebalance";
import FundRebalanceStep1 from "./RebalancingInfo/FundRebalance/Step1";
import FundRebalanceStep2 from "./RebalancingInfo/FundRebalance/Step2";
import FundRebalanceStep3 from "./RebalancingInfo/FundRebalance/Step3";
import {
  sleeveDataStageOneData1,
  sleeveDataStageOneData2,
} from "../../dummyData";
import FundRebalanceStep4 from "./RebalancingInfo/FundRebalance/Step4";
import FundRebalanceStep5 from "./RebalancingInfo/FundRebalance/Step5";

const RebalancerAllocation = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentStep(0);
  };

  const goToNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <Container maxWidth="100%" sx={{ marginTop: 2, marginBottom: 3 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Rebalance Tabs"
              >
                <Tab label="Fund Rebalance" />
                <Tab label="Sleeve Rebalance" />
              </Tabs>
            </Box>

            {activeTab === 0 && (
              <Box>
                {currentStep === 0 ? (
                  <FundRebalance onNext={goToNextStep} />
                ) : currentStep === 1 ? (
                  <FundRebalanceStep1
                    onPrevious={() => setCurrentStep(0)}
                    onNext={() => setCurrentStep(2)}
                    sleeve={false}
                    sleeveDataStageOne={sleeveDataStageOneData2}
                  />
                ) : currentStep === 2 ? (
                  <FundRebalanceStep4
                    onPrevious={() => setCurrentStep(1)}
                    setCurrentStep={setCurrentStep}
                  />
                ) : currentStep === 3 ? (
                  <FundRebalanceStep2
                    onPrevious={() => setCurrentStep(2)}
                    onNext={goToNextStep}
                  />
                ) : currentStep === 4 ? (
                  <FundRebalanceStep5
                    onPrevious={() => setCurrentStep(3)}
                    onNext={goToNextStep}
                  />
                ) : (
                  <FundRebalanceStep3
                    onPrevious={() => setCurrentStep(4)}
                    onNext={goToNextStep}
                    sleeve={false}
                    sleeveDataStageOne={sleeveDataStageOneData2}
                  />
                )}
              </Box>
            )}

            {activeTab === 1 && (
              <Box>
                {currentStep === 0 ? (
                  <SleeveReblance setCurrentStep={setCurrentStep} />
                ) : currentStep === 1 ? (
                  <FundRebalanceStep1
                    onPrevious={() => setCurrentStep(0)}
                    onNext={() => setCurrentStep(2)}
                    sleeve={true}
                  />
                ) : currentStep === 2 ? (
                  <FundRebalanceStep4
                    onPrevious={() => setCurrentStep(1)}
                    setCurrentStep={setCurrentStep}
                  />
                ) : currentStep === 3 ? (
                  <FundRebalanceStep2
                    onPrevious={() => setCurrentStep(2)}
                    setCurrentStep={setCurrentStep}
                  />
                ) : currentStep === 4 ? (
                  <FundRebalanceStep5
                    onPrevious={() => setCurrentStep(3)}
                    onNext={goToNextStep}
                  />
                ) : (
                  <FundRebalanceStep3
                    onPrevious={() => setCurrentStep(4)}
                    onNext={goToNextStep}
                    sleeve={true}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RebalancerAllocation;
